import React from 'react';
import styled from 'styled-components';

const Backdrop = (props) => {
    return (
        props.show ? <Container onClick={ props.clicked }></Container> : null
    );
};

export default Backdrop;

const Container = styled.div`
    width: 100%;
    height: 100%;
    position: fixed;
    // this is so it's above mui's modal z-index 1300
    z-index: 1301;
    left: 0;
    top: 0;
    background-color: rgba(0, 0, 0, 0.7);
`;